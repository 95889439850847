import {mapGetters} from "vuex";

export const companySettings = {
    computed: {
        ...mapGetters('settings', {
            loading: 'loading',
            settings: 'settings',
        }),
    },

    methods: {
        saveSettingValue(settingName, value) {
            this.$store.dispatch('settings/saveSetting', {name: settingName, value})
                .then(data => {
                    this.$notify.success({
                        title: 'Сохранено',
                        message: 'Настройка успешно сохранена'
                    });
                })
        },

        updateSettingValue (name, value) {
            this.$store.commit('settings/setSetting', {name, value})
        }
    }
}