<template>
  <div class="company-settings" v-loading="loading">

    <div class="company-settings-group">
      <div class="company-settings-group-name">Фиксированная шкала</div>
      <div class="company-settings-group-row" style="justify-content: normal;">
        <el-input
          style="width: unset"
          :value="settings.scale_fixed_pay_target_a"
          @input="updateSettingValue('scale_fixed_pay_target_a', $event)"
          @change="saveSettingValue('scale_fixed_pay_target_a', $event)"
        >
          <template slot="prepend">Целевое значение Min, %</template>
        </el-input>
        <el-input
          style="width: unset"
          :value="settings.scale_fixed_pay_target_c"
          @input="updateSettingValue('scale_fixed_pay_target_c', $event)"
          @change="saveSettingValue('scale_fixed_pay_target_c', $event)"
        >
          <template slot="prepend">Целевое значение Max, %</template>
        </el-input>
      </div>
      <div class="company-settings-group-row" style="justify-content: normal;">
        <el-input
          style="width: unset"
          :value="settings.scale_fixed_pay_pay_a"
          @input="updateSettingValue('scale_fixed_pay_pay_a', $event)"
          @change="saveSettingValue('scale_fixed_pay_pay_a', $event)"
        >
          <template slot="prepend">Выплата за достижение Min, %</template>
        </el-input>
        <el-input
          style="width: unset"
          :value="settings.scale_fixed_pay_pay_b"
          @input="updateSettingValue('scale_fixed_pay_pay_b', $event)"
          @change="saveSettingValue('scale_fixed_pay_pay_b', $event)"
        >
          <template slot="prepend">Выплата за достижение Target, %</template>
        </el-input>
        <el-input
          style="width: unset"
          :value="settings.scale_fixed_pay_pay_c"
          @input="updateSettingValue('scale_fixed_pay_pay_c', $event)"
          @change="saveSettingValue('scale_fixed_pay_pay_c', $event)"
        >
          <template slot="prepend">Выплата за достижение Max, %</template>
        </el-input>
      </div>
    </div>

    <div class="company-settings-group">
      <div class="company-settings-group-name">Корректировка годовых целей</div>
      <div class="company-settings-group-row" style="justify-content: normal;">
        <div>
          Начало корректировки
          <el-date-picker
            :value="settings.correction_date_start"
            @input="updateSettingValue('correction_date_start', $event)"
            @change="saveSettingValue('correction_date_start', $event)"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            :picker-options="{
              firstDayOfWeek: 1,
              disabledDate(time) {
                if( !settings.correction_date_end ){
                  return false;
                }
                return time.getTime() > (new Date().setDate((new Date(settings.correction_date_end).getDate()) - 1));
              },
            }"
          >
          </el-date-picker>
        </div>
        <div>
          Конец корректировки
          <el-date-picker
            :value="settings.correction_date_end"
            @input="updateSettingValue('correction_date_end', $event)"
            @change="saveSettingValue('correction_date_end', $event)"
            :disabled="!settings.correction_date_start"
            size="small"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            :picker-options="{
              firstDayOfWeek: 1,
              disabledDate(time) {
                return time.getTime() < new Date(settings.correction_date_start);
              },
            }"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <div class="company-settings-group">
      <div class="company-settings-group-name">Корпоративные цели</div>
      <div class="company-settings-group-row">
        <div style="width: 100%">
          <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
            <div>Корпоративные цели компании 1</div>
            <el-button
              size="mini"
              type="success"
              @click="saveSettingValue('corporate_target_1', settings.corporate_target_1)"
            >
              Сохранить
            </el-button>
          </div>
          <wysiwyg
            v-model="settings.corporate_target_1"
            :hideModules="{ bold: true }"
          ></wysiwyg>
        </div>
        <div style="width: 100%">
          <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
            <div>Корпоративные цели компании 2</div>
            <el-button
              size="mini"
              type="success"
              @click="saveSettingValue('corporate_target_2', settings.corporate_target_2)"
            >
              Сохранить
            </el-button>
          </div>
          <wysiwyg
            v-model="settings.corporate_target_2"
            :hideModules="{ bold: true }"
          ></wysiwyg>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {companySettings} from "@/mixins/companySettings";

export default {
  name: "company-settings",
  mixins: [companySettings],


  props: {},

  watch: {
    'settings.correction_date_start': function (newVal) {
      if(!newVal){
        this.saveSettingValue('correction_date_end', null);
        // this.settings.correction_date_end = null;
      }
    }
  },
  data() {
    return {
      r: '',
    }
  },
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss" scoped>
.company-settings {
  &-group {
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #cecece;
    }


    &-name {
      font-weight: bold;
    }

    &-row {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }
}
</style>